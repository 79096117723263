import React from "react";

export const Services = (props) => {
  const renderServiceSection = (d, index) => (
    <div key={index} className="col-md-4 service-info-container">
      <img src={d.image} alt={d.name} className="service-image" />
      <div className="service-desc">
        <h3>{d.name}</h3>
        {Object.keys(d.text).map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <h4>{section}</h4>
            {Array.isArray(d.text[section]) ? (
              <ul className="service-list">
                {d.text[section].map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{d.text[section]}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Here is a list of services offered by El Compa Suds LLC. We do
            charge an 8% state tax fee!
          </p>
        </div>
        <div className="row">
          {props.data ? props.data.map(renderServiceSection) : "loading"}
        </div>
      </div>
    </div>
  );
};
