import React, { useState, useRef, useEffect } from "react";

export const Navigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const portfolioRef = useRef(null);
  const testimonialsRef = useRef(null);
  const teamRef = useRef(null);
  const contactRef = useRef(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const smoothScrollToSection = (event, ref) => {
    if (ref.current) {
      event.preventDefault();
      setIsNavOpen(false); // Close the navigation menu after clicking on a link
      const offsetTop =
        ref.current.getBoundingClientRect().top + window.scrollY;
      let scrollDuration = 800; // Default scroll duration in milliseconds

      // Adjust scroll duration for smaller screens (e.g., phones)
      if (window.innerWidth <= 768) {
        scrollDuration = 1500; // Increase scroll duration for smaller screens
      }

      const startingY = window.scrollY;
      const diff = offsetTop - startingY;
      let start;

      // Use requestAnimationFrame to animate the scroll
      function step(timestamp) {
        if (!start) start = timestamp;
        const time = timestamp - start;
        const percent = Math.min(time / scrollDuration, 1);
        window.scrollTo(0, startingY + diff * percent);
        if (time < scrollDuration) {
          window.requestAnimationFrame(step);
        }
      }

      // Start the animation
      window.requestAnimationFrame(step);
    }
  };

  useEffect(() => {
    const closeNavOnLinkClick = () => {
      // Close the navigation menu when a link is clicked on mobile or tablet devices
      if (window.innerWidth <= 768) {
        setIsNavOpen(false);
      }
    };

    // Attach event listener to all links in the navigation menu
    const links = document.querySelectorAll(".page-scroll");
    links.forEach((link) => {
      link.addEventListener("click", closeNavOnLinkClick);
    });

    // Cleanup: Remove event listener on component unmount
    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", closeNavOnLinkClick);
      });
    };
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="navbar-header">
        <button
          className="navbar-toggle"
          type="button"
          onClick={toggleNav}
          aria-expanded={isNavOpen ? "true" : "false"}
          aria-controls="bs-example-navbar-collapse-1"
        >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
        <a className="navbar-brand page-scroll" href="#page-top">
          <div className="navbar-header" style={{ marginTop: "-15px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/img/logo-img.jpg"
                alt="Logo"
                style={{
                  width: "50px",
                  marginRight: "20px",
                }}
              />
              <span>El Compa Suds LLC</span>
            </div>
          </div>
        </a>
      </div>

      <div
        className={"collapse navbar-collapse" + (isNavOpen ? " in" : "")}
        id="bs-example-navbar-collapse-1"
      >
        <ul className="nav navbar-nav navbar-right">
          <li>
            <a
              href="#about"
              className="page-scroll"
              onClick={(e) => smoothScrollToSection(e, aboutRef)}
            >
              About
            </a>
          </li>
          <li>
            <a
              href="#services"
              className="page-scroll"
              onClick={(e) => smoothScrollToSection(e, servicesRef)}
            >
              Services
            </a>
          </li>
          <li>
            <a
              href="#portfolio"
              className="page-scroll"
              onClick={(e) => smoothScrollToSection(e, portfolioRef)}
            >
              Gallery
            </a>
          </li>
          <li>
            <a
              href="#testimonials"
              className="page-scroll"
              onClick={(e) => smoothScrollToSection(e, testimonialsRef)}
            >
              Testimonials
            </a>
          </li>
          <li>
            <a
              href="#team"
              className="page-scroll"
              onClick={(e) => smoothScrollToSection(e, teamRef)}
            >
              FAQ
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className="page-scroll"
              onClick={(e) => smoothScrollToSection(e, contactRef)}
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};
