import React from "react";
import faqData from "../data/data.json";

export const FAQ = () => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Frequently Asked Questions</h2>
          <div>
            {faqData.FAQ.map((faq, index) => (
              <div key={index} className="faq-item">
                <p className="faq-question">
                  <strong>Question:</strong> {faq.question}
                </p>
                <p className="faq-answer">
                  <strong>Answer:</strong> {faq.answer}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
